import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/acm-k9.jpeg"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="ACM-9"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center">
                ACM-K9 СО СКЛАДНОЙ РАМОЙ БЕЗ МЕХАНИЗМА ВНЕСЕНИЯ УДОБРЕНИЙ.
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">


                    Серия Ozdoken ACM-9 используется для рыхления, измельчения, подрезки, окучивания, защиты посевов от повреждения и приподнятия слоя почвы между культивируемыми пропашными культурами
                    <br />

                </p>
                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>
                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="Очищает поле от сорняков." />
                    <Prodfeat text="Обеспечивает лучшее проникновение удобрений в почву." />
                    <Prodfeat text="Благодаря пружинным лапами не прессует нижнию часть почвы и не предотвращает росту растений." />
                    <Prodfeat text="Культиватор удобен при использовании на каменистой земле." />
                    <Prodfeat text="Может быть использован тракторами с меньшей мощности." />
                    {/* <Prodfeat text="Культиватор обеспечивает экономит время в окучивание." /> */}
                    <Prodfeat text="32x10 РАЗМЕР ЛАПЫ" />
                    <Prodfeat text="150X100 ДВОЙНАЯ РАМА" />
                    <Prodfeat text="Доступно с 3 Лапами (45-50 см) Или с 5 Лапами (70-75 см) На Рабочих Органов." />
                </div>
                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОБОРУДОВАНИЕ ПРОДУКТА
                </h2>
                <div class="row">

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261929934087553-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. ПЕРЕДНЕПРИВОДНЫЕ КОЛЕСА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПЕРЕДНЕПРИВОДНЫЕ КОЛЕСА</strong><p class="h5">Металлические колеса помогают сеялке следовать идеально за трактором. Широкий размер создает идеальный контакт на почве и помогает оптимальной регулировки глубины.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261927878149781-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. КОЛЕСО ДЛЯ КОНТРОЛЯ ГЛУБИНЫ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КОЛЕСО ДЛЯ КОНТРОЛЯ ГЛУБИНЫ</strong><p class="h5">Колесо для контроля глубины 300 мм</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261930773154788-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. КОЛЕСО УДОБРЕНИЙ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КОЛЕСО УДОБРЕНИЙ</strong><p class="h5">Центральное колесо удобрений защищает посевы от повреждений, в отличие от боковых колес. Кроме того, во время работы легко следить за колесом.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261926953202189-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. СЕКЦИЯ АСМ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">СЕКЦИЯ АСМ</strong><p class="h5">Стандарт секция АСМ</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261932026560184-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. КРЕПКИЙ КОРПУС" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КРЕПКИЙ КОРПУС</strong><p class="h5">150X100 корпус обеспечивает надежность даже на больших моделях серии ACM.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261928908221865-b75--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. КАТОК" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КАТОК</strong><p class="h5">Катки установлены с усиленными подшипниками</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261927368837358-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. ПАРАЛЛЕЛОГРАММНОЕ СОЕДИНЕНИЕ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПАРАЛЛЕЛОГРАММНОЕ СОЕДИНЕНИЕ</strong><p class="h5"> Параллельное сцепное устройство устанавливается на повторно закрепляемые металлические втулки которые обеспечивают машина имеет высокий клиренс. Стандартная пружина прижимного давления с 3 ступенями регулировки.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261928365612894-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. ПРУЖИННЫЕ СТРЕЛЬЧАТЫЕ ЛАПЫ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПРУЖИННЫЕ СТРЕЛЬЧАТЫЕ ЛАПЫ</strong><p class="h5"></p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261929426657367-b75--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. БОРОЗДНИК" style={{ marginBottom: `1.45rem` }} /><strong class="h4">БОРОЗДНИК</strong><p class="h5">В зависимости от типа урожая, можно выбрать фиксированный или регулируемый бороздник.</p> </div> </div> </div>

                </div>
                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                        <thead class="background-dark-gray-ozdo"><tr>
                            <th height="77">MОДЕЛИ</th>
                            
                            <th>РАССТОЯНИЯ<br />МЕЖДУ<br />РЯДАМИ<br />(см)</th>
                            <th>КОЛИЧЕСТВО<br />ЛАП <br />КАЖДОЙ <br />СЕКЦИИ</th>
                            <th>ТРАНСПОРТНАЯ<br />ШИРИНА <br />(см) </th>
                            <th>ВЕС <br />(кг)</th>
                            <th colspan="2" align="center" valign="middle">ТРЕБУЕМАЯ<br />МОЩНОСТЬ,<br /> л.с./кВт</th>
                            <th>ПЛАСТИКОВЫЙ<br />БУНКЕР<br />ДЛЯ<br />УДОБРЕНИЙ</th>
                        </tr>
                        </thead><tbody>
                            <tr>
                                <td height="32">ACM&#8209;K9</td>
                                
                                <td>70/75</td>
                                <td>5</td>
                                <td>600</td>
                                <td>1050</td>
                                <td>90-120</td>
                                <td>67-90</td>
                                <td>-</td>
                            </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;